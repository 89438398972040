import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Excerpts, Pagination } from "../../components"

const ExcerptsPage = ({ data, pageContext }) => {
  const {
    allAirtable: { nodes: excerpts },
  } = data
  return (
    <Wrapper>
      <Layout>
        <Excerpts title="Összes egyperces" excerpts={excerpts} page />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Excertps.js  */}
  background: var(--clr-light-7);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query ExcerptsQuery($skip: Int, $limit: Int) {
    allAirtable(
      filter: { table: { eq: "Excerpts" } }
      limit: $limit
      skip: $skip
      sort: { fields: data___excerpts_row, order: DESC }
    ) {
      nodes {
        recordId
        id
        data {
          excerpts_title
          excerpts_excerpt
          excerpts_row
          excerpts_text {
            childMarkdownRemark {
              html
            }
          }
          excerpts_author
          excerpts_source
          excerpts_created
          excerpts_icon {
            localFiles {
              childImageSharp {
                fixed(width: 64, height: 64) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ExcerptsPage
